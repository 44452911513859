import React from 'react'
import MaskedInput from "react-text-mask";
import {masks} from "../../utils/mask.util";

function DocumentInput(props) {
    const [document, setDocument] = React.useState(props.disabled ? props.disabled : props.value.replace(/\D/g, ""));

    const { inputRef, ...other } = props;

    const onChangeDocument = e => {
        const result = e.target.value
        props.onChange(result)
        setDocument(result)
    }

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            onChange={onChangeDocument}
             mask={
                (document ? document.replace(/\D/g, "") : "").length === 11
                    ? masks.documents.cpf
                    : masks.documents.cnpj
            }
            showMask
            guide={false}
        />
    );
}

export default DocumentInput
